import React, {useEffect, useState} from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import ActionButton from "../components/actionButton"

const Covid = ({data}) => {

  // Single Image Data
  // const images = {
  //   image1: data.image1.childImageSharp.fluid,
  // }

  const [dateChanged, setDateChanged] = useState('0')
    useEffect(() => {
    var fetchUrl = "/freiezimmer.json" // props.location.origin + "/freiezimmer.json"
    // console.log("Identified current location as: " + props.location.origin)
    // console.log("Getting URL: " + fetchUrl)
    // get concerts data from .json file
    fetch(`${fetchUrl}`) // actual: https://dev11.lacreme.digital/concerts_data.json // local test/dummy: ../concerts_data_dummy.json
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
        // console.log("fetched data: " + resultData.value)
        setDateChanged(resultData.covidDateChanged)
        })
    })

  return (
    <Layout title="Covid-19 Massnahmen" slogan="Die Sicherheit unserer Bewohnenden liegt uns am Herzen" headerImage={data.image1.childImageSharp.fluid}>
      <h4>Aktuelle Informationen</h4>
      <p>Bitte lesen Sie die nachfolgenden Dokumente zu den aktuellen Covid-19 Massnahmen im Altersheim Bannau.</p>
      <ul style={{margin: 0}}>
      <ActionButton type="icon-pdf" address="/download/aktuelle-informationen-covid.pdf" text="Aktuelle Informationen Covid" />
      {/* <ActionButton type="icon-pdf" address="/download/Covid-19-Brief-Angehoerige-Sept-21.pdf" text={"Information Brief Angehörige (September 2021)"} />
      <ActionButton type="icon-pdf" address="/download/Covid-19-Brief-Angehoerige.pdf" text={"Information Brief Angehörige ("+dateChanged+")"} /> */}
      {/* <ActionButton type="icon-pdf" address="/download/Covid-19-Brief-Angehoerige.pdf" text="Information Brief Angehörige (27.02.21)" /> */}
      <ActionButton type="icon-pdf" address="/download/covid-checkin.pdf" text="Check-In / Registrierung" />
      
      <hr style={{margin: '1em auto'}}/>
      <ActionButton type="icon-video" address="https://www.youtube.com/watch?v=0uJQnrdAbQ4" text="Video Händedesinfektion" />
      <ActionButton type="icon-video" address="https://www.youtube.com/watch?v=GNkQKutS8cg" text="Video Verwendung einer Maske" />
      <ActionButton type="icon-video" address="https://www.youtube.com/watch?v=FRi2FTEuY9g" text="Video Händewaschen" />
      </ul>
    </Layout>
  ) 
}

export default Covid

export const pageQuery = graphql`
  query {
    image1: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-start-eingang.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`